import React from "react";
import styled from "styled-components";
import { Box } from "grommet";
import { Container } from "../elements/Layout";
import SEO from "../components/SEO";
import { Title } from "../elements/Text";
import ContactSection from "../components/ContactSection";
import theme from "../../config/theme";
import { EnvelopeIcon, PhoneIcon, PinIcon } from "../styles/icons";
import notas from "../images/notas.jpg";

const ContactPage = styled.div`
  padding-top: 40px;

  .title {
    align-self: center;
  }

  .boxes {
    margin-bottom: 100px;
    justify-content: center;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }

  .image-wrapper {
    display: none;
    max-width: 550px;
    width: 100%;
    height: auto;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .address-box {
    padding: 40px;
    background-color: ${theme.colors.brand};
    color: ${theme.colors.background};
    font-size: 18px;
    width: 100%;
    max-width: 550px;
    font-weight: 500;

    a {
      color: ${theme.colors.background};
    }

    .icon {
      margin-right: 20px;
      margin-top: 3px;
      width: 21px;
      height: 21px;

      i {
        filter: brightness(0);
        display: block;
      }
    }

    .info-item {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  @media (min-width: ${theme.breakpoints.large}) {
    padding-top: 80px;

    .title {
      align-self: flex-start;
    }

    .image-wrapper {
      display: block;
    }

    .gap {
      width: 70px;
    }
  }
`;

const Contact = ({ location }) => (
  <ContactPage>
    <SEO title="Contact" location={location} />
    <section>
      <Container>
        <Box basis="full">
          <Title level="2" className="title" decorator margin={{ bottom: "large" }}>
            Contact
          </Title>
        </Box>

        <Box direction="row" className="boxes">
          <Box className="address-box">
            <Box direction="row" className="info-item">
              <div className="icon">
                <PinIcon />
              </div>
              TitanTech, s.r.o.
              <br />
              Potocká 58/7, Kohoutovice
              <br />
              623 00 Brno
              <br />
              Czech republic
              <br />
            </Box>
            <Box direction="row" className="info-item">
              <div className="icon">
                <PhoneIcon />
              </div>
              <Box direction="column">
                +421 908 883 366
                <br />
                +420 603 330 571
              </Box>
            </Box>
            <Box direction="row" className="info-item">
              <div className="icon">
                <EnvelopeIcon />
              </div>
              <Box direction="column">
                <a href="mail&#116;o&#58;i&#37;&#54;E&#37;66%&#54;F%40tita&#110;&#37;64e&#37;73ig&#37;6&#69;&#46;eu">
                  info&#64;&#116;itandesign&#46;e&#117;
                </a>
              </Box>
            </Box>
          </Box>
          <div className="gap" />
          <Box className="image-wrapper">
            <img src={notas} alt="contact" />
          </Box>
        </Box>
      </Container>
      <ContactSection />
    </section>
  </ContactPage>
);

export default Contact;
