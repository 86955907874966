import React, { useEffect, useRef, useState } from "react";
import { Box, TextArea, TextInput } from "grommet";
import styled from "styled-components";
import Button from "../elements/Button";
import theme from "../../config/theme";
import Container from "../core/Container";

const StyledForm = styled.form`
  .row {
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 20px;
  }

  textarea {
    min-height: 272px;
    border-width: 0 0 1px 0;
    padding: 0;
    margin-bottom: 30px;
  }

  .button-wrapper {
    align-items: center;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    .row {
      flex-direction: row;
    }

    .button-wrapper {
      align-items: stretch;
    }

    .form-group {
      margin-bottom: 0;

      padding: 0 30px;
    }

    textarea {
      border-width: 0 0 0 1px;
      padding: 6px 22px;
      margin-bottom: 0;
    }
  }
`;

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const apiClient = useRef(null);
  const messagesService = useRef(null);
  const form = useRef(null);

  useEffect(() => {
    const container = Container.getInstance();
    apiClient.current = container.getApiClient();
    messagesService.current = container.getMessagesService();
    form.current.reset();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    const { name, email, phone, message } = e.target.elements;
    setLoading(true);

    apiClient.current
      .post("messages/contact", {
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value
      })
      .then(response => {
        messagesService.current.addSuccess(response.message);
        form.current.reset();
      })
      .catch(err => {
        messagesService.current.addError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <StyledForm onSubmit={handleSubmit} ref={form}>
      <Box flex={false} gap="20px">
        <Box className="row">
          <Box className="form-group" basis="full" gap="30px">
            <Box>
              <TextInput name="name" required type="text" placeholder="Name" disabled={loading} />
            </Box>
            <Box>
              <TextInput name="email" required type="email" placeholder="Email" disabled={loading} />
            </Box>
            <Box>
              <TextInput name="phone" type="phone" placeholder="Phone" disabled={loading} />
            </Box>
          </Box>
          <Box direction="column" basis="full">
            <TextArea name="message" resize={false} required placeholder="Message" disabled={loading} />
            <Box className="button-wrapper" width="full">
              <Button type="submit" label="Send" primary color="brand" disabled={loading} />
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledForm>
  );
};

export default ContactForm;
