import React from "react";
import { Box, Heading, Text } from "grommet";
import styled from "styled-components";
import { Container } from "../elements/Layout";
import ContactForm from "./ContactForm";
import theme from "../../config/theme";
import bg from "../images/trencin.jpg";
import { FadeInUp } from "../elements/Animations";

const ContactBackground = styled.div`
  padding-bottom: 60px;

  .form-box {
    .gap {
      height: 30px;
    }
  }

  .title {
    width: 100%;
    max-width: none;
  }

  .text {
    align-items: center;
  }

  .content {
    text-align: center;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    padding-top: 200px;
    padding-bottom: 200px;
    background: linear-gradient(to bottom, rgba(33, 33, 54, 1), rgba(33, 33, 54, 0.5) 10%), url(${bg}) no-repeat center;
    background-size: cover;
    background-position: top;

    .form-box {
      background-color: ${theme.colors.dark1};
      box-shadow: ${theme.boxShadows.small};

      .gap {
        height: 0;
      }
    }

    .content {
      padding: 33px 30px;
      text-align: left;
    }

    .text {
      align-items: flex-start;
    }
  }
`;

const ContactSection = () => (
  <ContactBackground>
    <Container>
      <FadeInUp>
        <Box align="center" justify="center" width="full" height="full">
          <Box className="form-box" width="800px">
            <div className="content">
              <Heading size="large" level="4" className="title" margin={{ bottom: "small" }}>
                Send us a message
              </Heading>
              <Box width="full" className="text">
                <Text weight={200} size="14px" style={{ maxWidth: "380px" }}>
                  We are happy to answer any questions you might have. Just drop us a line and we will reach out to you
                  asap.
                </Text>
              </Box>
            </div>
            <div className="gap" />
            <ContactForm />
          </Box>
        </Box>
      </FadeInUp>
    </Container>
  </ContactBackground>
);

export default ContactSection;
